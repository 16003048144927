import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { toHTML } from '../util/utils';

import Layout from '../components/Layout';
import FAQBackground from '../img/bg/faq-bg.svg';

export const FAQPageTemplate = ({ title, questions, cta }) => {
  const [ openTabIndex, setOpenTabIndex ] = useState(0);

  return (
    <>
      <section className="bg-gray-50">
        <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto">
            <h2 className="text-center text-3xl leading-9 font-extrabold text-gray-900 sm:text-5xl sm:leading-10">
              {title}
            </h2>
            <div className="mt-6 border-t-2 border-gray-200 pt-6">
              <dl>
                {questions.map((q, idx) => {
                  return (
                    <div className={`${idx !== 0 && 'mt-6 border-t border-gray-200 pt-6'}`} key={idx}>
                      <div>
                        <dt className="text-lg leading-7">
                          {/* <!-- Expand/collapse question button --> */}
                          <button onClick={() => setOpenTabIndex(idx)} className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900">
                            <span className="font-medium text-gray-900">
                              {q.question}
                            </span>
                            <span className="ml-6 h-7 flex items-center">
                              {/* <!--
                                Expand/collapse icon, toggle classes based on question open state.

                                Open: "-rotate-180", Closed: "rotate-0"
                              --> */}
                              <svg className={`${idx === openTabIndex ? 'rotate-180' : 'rotate-0'} h-6 w-6 transform`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"/>
                              </svg>
                            </span>
                          </button>
                        </dt>
                        <dd className={`${openTabIndex !== idx && 'hidden'} mt-2 pr-12`}>
                          <div className="text-gray-500 prose" 
                            dangerouslySetInnerHTML={{ __html: toHTML(q.answer) }}
                          >
                          </div>
                        </dd>
                      </div>
                    </div>
                  );
                })}        
              </dl>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-green-500">
        <div className="max-w-5xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-100 sm:text-4xl sm:leading-10">
            {cta.heading}
            <br />
            <span className="text-2xl sm:text-3xl text-green-200">
              {cta.subheading}
            </span>
          </h2>

          <div className="mt-8 flex lg:flex-shrink-0 w-full sm:w-80 lg:mt-0">
            <div className="sm:ml-3 w-full inline-flex rounded-md shadow">
              <Link to="/#cash-offer" className="w-full inline-flex items-center justify-center px-5 py-3 sm:py-4 border border-transparent text-base sm:text-lg leading-6 font-medium rounded-md text-green-600 bg-white hover:text-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out">
                Get my cash offer
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>    
  )
}

FAQPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  questions: PropTypes.array,
}

const FAQPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <FAQPageTemplate
        title={frontmatter.title}
        questions={frontmatter.questions}
        cta={frontmatter.cta}
      />
    </Layout>
  )
}

FAQPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default FAQPage;

export const faqPageQuery = graphql`
  query FAQPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        questions {
          question
          answer
        }
        cta {
          heading
          subheading
        }
      }
    }
  }
`